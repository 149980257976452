import { Anchor, Flex, Text, Image } from "@mantine/core";
import classes from "@/styles/Register.module.css";

const AuthSocialMedia = ({
  href,
  title,
  imgUrl,
}: {
  href: string;
  title: string;
  imgUrl: string;
}) => {
  return (
    <Anchor href={href} className={classes.authLink}>
      <Flex
        ff="Inter"
        justify="flex-start"
        align="center"
        fz={{ base: 16, md: 18 }}
        gap={4}
      >
        <Text>{title}</Text>
        <Image
          w={40}
          h={40}
          src={imgUrl}
          style={{
            boxShadow: "4px 4px 28px 0px rgba(0, 0, 0, 0.1)",
            borderRadius: "50%",
          }}
          alt="icon"
        />
      </Flex>
    </Anchor>
  );
};

export const AuthSocialMediaMobile = ({
  href,
  imgUrl,
}: {
  href: string;
  imgUrl: string;
}) => {
  return (
    <Anchor href={href} className={classes.authLink}>
      <Image
        w={40}
        h={40}
        src={imgUrl}
        style={{
          boxShadow: "4px 4px 28px 0px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
        }}
        alt="icon"
      />
    </Anchor>
  );
};
export default AuthSocialMedia;
