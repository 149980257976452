import { Group } from "@mantine/core";
import AuthSocialMedia, {
  AuthSocialMediaMobile,
} from "@/components/AuthSocialMedia";
import DividerWithText from "@/components/DividerWithText";

const SocialMediaFooterLoginSection = ({
  google,
  facebook,
}: {
  google: { label: string; url: string };
  facebook: { label: string; url: string };
}) => {
  return (
    <>
      <DividerWithText text="Or" />
      <Group display={{ base: "flex", xs: "none" }} justify="center">
        <AuthSocialMediaMobile
          href={google.url}
          imgUrl={"/images/google.svg"}
        />
        {/* TODO : Enable after we recieve facebook credentials from client */}
        {/* <AuthSocialMediaMobile
          href={facebook.url}
          imgUrl={"/images/facebook.svg"}
        /> */}
      </Group>
      <Group
        display={{ base: "none", xs: "flex" }}
        justify="center"
        gap={18}
        w="100%"
      >
        <AuthSocialMedia
          href={google.url}
          title={google.label}
          imgUrl={"/images/google.svg"}
        />
        {/* TODO : Enable after we recieve facebook credentials from client */}
        {/* <AuthSocialMedia
          href={facebook.url}
          title={facebook.label}
          imgUrl={"/images/facebook.svg"}
        /> */}
      </Group>
    </>
  );
};
export default SocialMediaFooterLoginSection;
