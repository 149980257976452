import { BoxProps, Center, Divider } from "@mantine/core";

const DividerWithText = ({ text, ...props }: { text: string } & BoxProps) => {
  return (
    <Center w="100%" pos="relative" {...props}>
      <Divider pos="absolute" top="50%" w="75%" h={1} bg="#D7D7D7" />
      <Center
        pos="relative"
        ff="Inter"
        fz={{ base: 12, sm: 14 }}
        fw={300}
        lh="1.11"
        c="#808080"
        p={6}
        mx="auto"
        bg="white"
        maw="max-content"
        style={{
          zIndex: 1,

          borderRadius: "4px",
        }}
        tt="uppercase"
      >
        {text}
      </Center>
    </Center>
  );
};

export default DividerWithText;
