import { Stack, Text, Box, Button, Image, Flex, Center } from "@mantine/core";
import CustomInputField from "@/components/form/CustomInputField";
import { UseFormReturn } from "react-hook-form";
import { LoginFormEnum } from "@/types/common";
import { EmailLoginData } from "@/types/auth";
import { IconArrowRight } from "@tabler/icons-react";
import Link from "next/link";
import { useHover } from "@mantine/hooks";
import { validateEmail } from "@/utils/validation";
import { requiredErrorMessage } from "@/root/utils";
import SocialMediaFooterLoginSection from "@/components/SocialMediaFooterLoginSection";
import { getApiUrl } from "@/env";
import SubSectionTitle from "@/components/textComponent/title/SubSectionTitle";
import MetaText from "@/components/textComponent/MetaText";

const loginFormId = "loginFormId";

const LoginForm = ({
  hform,
  isDesktop,
  loader,
  onLogin,
}: {
  isDesktop?: boolean;
  loader: boolean;
  hform: UseFormReturn<EmailLoginData>;
  onLogin: ({ payload }: { payload: EmailLoginData }) => void;
}) => {
  const { hovered: forgotPassHovered, ref: forgotPassRef } = useHover();
  const { hovered: signUpHovered, ref: signUpRef } =
    useHover<HTMLAnchorElement>();

  return (
    <Stack
      h="100%"
      py={{ base: "20px", md: "30px", lg: "50px" }}
      px={{ base: "20px", md: "30px", lg: "40px" }}
      ff="Vesper Libre"
      w="100%"
      pos="relative"
      style={{
        borderRadius: "20px",
        background: "white",
        borderTopLeftRadius: isDesktop ? "0" : "20px",
        borderBottomLeftRadius: isDesktop ? "0" : "20px",
      }}
      mih={{ base: "80svh", md: "auto" }}
    >
      <Stack>
        {!isDesktop && (
          <Box w="100%" style={{ zIndex: 2 }}>
            <Link href="/">
              <Image
                mx="auto"
                w="100px"
                src="/images/uthsavColored.webp"
                alt="logo"
              />
            </Link>
          </Box>
        )}

        <Box>
          <SubSectionTitle ta={{ base: "center", md: "start" }} py={8}>
            Login
          </SubSectionTitle>
          <MetaText ta={isDesktop ? "start" : "center"}>
            Pick up where you left off and let the{" "}
            <Text span style={{ textWrap: "nowrap" }} fz="inherit">
              wedding planning
            </Text>{" "}
            magic continue!
          </MetaText>
        </Box>
        <form
          id={loginFormId}
          onSubmit={hform.handleSubmit((data: EmailLoginData) => {
            onLogin({ payload: data });
          })}
        >
          <Flex
            direction="column"
            gap={16}
            pt={{ base: 20, md: 16, lg: 24 }}
            w="100%"
            align="center"
          >
            <CustomInputField
              hform={hform}
              name="email"
              placeholder={LoginFormEnum.email}
              rules={{
                required: requiredErrorMessage(LoginFormEnum.email),
                validate: {
                  validate: (value) => {
                    if (!validateEmail(value)) {
                      return "Please enter valid email address";
                    }
                    return true;
                  },
                },
              }}
              style={{ width: "100%", maxWidth: isDesktop ? "100%" : "640px" }}
            />
            <CustomInputField
              hform={hform}
              name="password"
              type="password"
              placeholder={LoginFormEnum.password}
              rules={{
                required: requiredErrorMessage(LoginFormEnum.password),
                validate: {
                  validate: (value) => {
                    if (value.length > 30) {
                      return "Password should be less than 30 characters";
                    }
                    return true;
                  },
                },
              }}
              style={{ width: "100%", maxWidth: isDesktop ? "100%" : "640px" }}
            />
          </Flex>
        </form>
        <Flex
          justify="flex-end"
          style={{ width: "100%", maxWidth: isDesktop ? "100%" : "640px" }}
          mx="auto"
        >
          <Link href="/reset">
            <Text
              c="#30B8A0"
              ta={{
                base: "left",
                xs: "right",
              }}
              ff="Vesper Libre"
              fz={14}
              fw={600}
              ref={forgotPassRef}
              style={{
                textDecoration: forgotPassHovered ? "underline" : "none",
              }}
            >
              Forgot Your Password?
            </Text>
          </Link>
        </Flex>

        <Button
          color="primaryPink.2"
          rightSection={<IconArrowRight size={24} />}
          display={{ base: "none", md: "block" }}
          ff="Inter"
          w="max-content"
          mih={{ base: 48, md: 55 }}
          radius={100}
          type="submit"
          px={24}
          form={loginFormId}
        >
          <Text fw={600} fz={18}>
            Login
          </Text>
        </Button>

        <Flex
          pos="fixed"
          bottom="0%"
          w="100%"
          left="0%"
          bg="transparent"
          display={{
            base: "flex",
            md: "none",
          }}
          px={{ base: 20, md: 0 }}
          py={{ base: 10, md: 0 }}
          style={{ justifyContent: "center", zIndex: 30 }}
        >
          <Button
            loading={loader}
            color="primaryPink.2"
            rightSection={<IconArrowRight size={23} />}
            display={{ base: "block", md: "none" }}
            ff="Inter"
            w="100%"
            mih={{ base: 48, md: 55 }}
            radius={100}
            type="submit"
            form={loginFormId}
            style={{ maxWidth: "640px" }}
          >
            <Text fw={600} fz={18}>
              Login
            </Text>
          </Button>
        </Flex>
      </Stack>
      <Stack
        justify="flex-end"
        gap={14}
        w="100%"
        h="100%"
        pb={{ md: 8, lg: 16 }}
      >
        <Center>
          <Text c="black" ff="Vesper Libre" fz={14} ta="center">
            No account yet?
            <Link
              style={{
                textDecoration: signUpHovered ? "underline" : "none",
                fontWeight: "400",
                color: "var(--mantine-color-primaryPink-2)",
                paddingInline: "5px",
                fontFamily: "Vesper Libre",
              }}
              ref={signUpRef}
              passHref
              href="/register"
            >
              <Text span fz={14} fw={600} style={{ textWrap: "nowrap" }}>
                Sign Up
              </Text>
            </Link>
            <Text display={{ base: "block", sm: "inline" }} fz={14} span>
              and let’s get started!
            </Text>
          </Text>
        </Center>
        <SocialMediaFooterLoginSection
          google={{
            label: "Sign in with Google",
            url: `${getApiUrl()}/auth/google`,
          }}
          facebook={{
            label: "Sign in with Facebook",
            url: `${getApiUrl()}/auth/facebook`,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default LoginForm;
