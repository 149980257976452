import { useState } from "react";
/* use this hook if you want to make an pos request using makeApiCall () func so that you can place loading state wherever you want*/
export const useLoading = (initialState: boolean) => {
  const [isLoading, setisLoading] = useState<boolean>(initialState);
  const startLoading = () => {
    setisLoading(true);
  };
  const stopLoading = () => {
    setisLoading(false);
  };

  return { startLoading, stopLoading, isLoading };
};
