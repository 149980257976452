import LeftPane from "@/components/common/commonLeftPane";
import LoginForm from "@/components/LoginForm";
import { useMediaQuery } from "@mantine/hooks";
import { useForm } from "react-hook-form";
import { EmailLoginData } from "@/types/auth";
import { useApi } from "@/hooks/useApi";
import { getQueryClient } from "@/api";
import { useRouter } from "next/router";
import Head from "next/head";
import { useLoading } from "@/hooks/useLoading";
import AuthPageContainer from "@/components/auth/AuthPageContainer";

const Login = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const hform = useForm<EmailLoginData>({
    mode: "onChange",
  });
  const router = useRouter();

  const { makeApiCall } = useApi();
  const { startLoading, stopLoading, isLoading } = useLoading(false);
  const onLogin = ({ payload }: { payload: EmailLoginData }) => {
    makeApiCall({
      fetcherFn: async () => {
        startLoading();
        const response = await getQueryClient().auth.emailLogin.mutation({
          body: {
            ...payload,
            email: payload.email.toLowerCase(),
            isVendorLogin: false,
          },
        });
        return response;
      },
      successMsgProps: { message: "Success! Logging you in." },
      onSuccessFn: (response) => {
        stopLoading();
        if (response.status === 200) {
          router.replace("/profile");
        }
      },
      onFailureFn: () => {
        stopLoading();
      },
    });
  };
  return (
    <>
      <Head>
        <title>Uthsav | Login</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="description"
          content="Uthsav - Login - Wedding planning | Event planning | Indian Weddings"
        />
        <style>{`body { background-color: var(--mantine-color-secondaryPink-1); }`}</style>
      </Head>
      <AuthPageContainer
        leftSection={
          <LeftPane
            bgColor="#30B8A0"
            heading="Uthsav is a free app to help guide you through the romantic journey to your big&nbsp;day."
            subheading1="Our checklists, budget planning tools, and collaborations are designed to make this process stress-free and fun for you and your loved one."
          />
        }
        rightSection={
          <LoginForm
            loader={isLoading}
            hform={hform}
            isDesktop={isDesktop}
            onLogin={onLogin}
          />
        }
      />
    </>
  );
};
export default Login;
