import { Box, Text, Stack, Image, Flex } from "@mantine/core";
import Link from "next/link";
import React from "react";
import SubSectionTitle from "@/components/textComponent/title/SubSectionTitle";
import MetaText from "@/components/textComponent/MetaText";

const LeftPane = ({
  heading,
  subheading1,
  subHeading2,
  bgColor,
}: {
  heading: string;
  subheading1: string;
  subHeading2?: string;
  bgColor: string;
}) => {
  return (
    <Stack
      display={{
        base: "none",
        md: "flex",
      }}
      py={{ md: "30px", lg: "50px" }}
      px={{ md: "30px", lg: "40px" }}
      style={{
        backgroundColor: bgColor,
        borderRadius: "25px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
      }}
      c="white"
      h="100%"
      w="100%"
      pos="relative"
    >
      <>
        <Box maw="160px" w="100%">
          <Link href="/">
            <Image alt="uthsav-logo" src="/images/uthsav.webp" />
          </Link>
        </Box>
        <Flex direction="column" gap={16} justify="flex-start" h="100%" pt={80}>
          <SubSectionTitle ta="center" w="100%" c="white">
            {heading}
          </SubSectionTitle>
          <MetaText
            mt={16}
            ta="center"
            mx="auto"
            w="100%"
            maw={"26.7rem"}
            c="white"
          >
            {subheading1 ? subheading1 : ""}
          </MetaText>
          {subHeading2 && (
            <Text
              ta="center"
              mx="auto"
              fz={16}
              ff="Inter"
              style={{ lineHeight: "1.478" }}
              fw={400}
              w="100%"
              maw={"26.7rem"}
            >
              {subHeading2}
            </Text>
          )}
        </Flex>
      </>
      <Box
        w="100%"
        display={{ base: "none", md: "block" }}
        pos="absolute"
        bottom="0%"
        left="0%"
      >
        <Image alt="left-pane-uthsav-logo" src="/images/designuthsav.webp" />
      </Box>
    </Stack>
  );
};

export default LeftPane;
