import { Flex, FlexProps, Grid } from "@mantine/core";

const AuthPageContainer = ({
  leftSection,
  rightSection,
  ...props
}: {
  leftSection: React.ReactNode;
  rightSection: React.ReactNode;
} & FlexProps) => {
  return (
    <Flex
      w="100%"
      bg="secondaryPink.1"
      mih="100svh"
      align="center"
      px={{ base: 20, lg: 40 }}
      pt={{ base: 40, md: 34 }}
      pb={{ base: 80, md: 34 }}
      {...props}
    >
      <Grid gutter={0} w="100%" maw={1600} mx="auto">
        <Grid.Col span={{ base: 0, md: 5 }}>{leftSection}</Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }}>{rightSection}</Grid.Col>
      </Grid>
    </Flex>
  );
};
export default AuthPageContainer;
