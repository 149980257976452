import { infer as ZodInfer } from "zod";
import { editUserInfo } from "contract/user/types";
import {
  ChangePasswordSchema,
  SendForgetPasswordEmail,
  SetPasswordSchema,
} from "contract/auth/types";

export enum VendorFormEnum {
  email = "Email address",
  phone = "Phone number",
  firstName = "First Name",
  lastName = "Last Name",
  zipCode = "Zipcode",
  city = "City",
  budget = "Budget",
  companyName = "Company name",
  companyDescription = "What's your company is all about and How can you make it easier for wedding planners?",
  category = "Category",
  password = "Password",
  confirmPassword = "Confirm password",
}

export enum LoginFormEnum {
  email = "Email address",
  password = "Password",
}

export enum RegisterFormEnum {
  firstname = "First name",
  lastname = "Last name",
  email = "Email address",
  phone = "Phone number",
  country = "Country",
  password = "Password",
  confirmPassword = "Confirm password",
  zipCode = "Zipcode",
  // city = "City",
}

export enum BasicInfoEnum {
  firstname = "First name",
  lastname = "Last name",
  email = "Email",
  phone = "Phone",
}

export enum ResetPasswordFormEnum {
  email = "Email address",
}

export interface VendorForm {
  email: string;
  phone: string;
  location: string;
  budget: string;
  companyName: string;
  companyDescription: string;
  companyRole: string;
}

export enum CountryDetail {
  CountryProperty,
}

export interface CustomSelectOption {
  value: string;
  label: string;
  __isNew__?: boolean;
}

export type editUserInfoType = ZodInfer<typeof editUserInfo>;
export type ModifiedEditUserInfo = editUserInfoType & {
  email: string;
  phoneCode: string | null;
};
export type resetPassword = ZodInfer<typeof SendForgetPasswordEmail>;

export type changePasswordType = ZodInfer<typeof ChangePasswordSchema>;
export type setPasswordType = ZodInfer<typeof SetPasswordSchema>;

export const successToastId = "successToastId";
export const errorToastId = "errorToastId";
